import React, { useEffect, useRef } from "react";
import mowgli from "../Image/token-mowgli.png";
import baloo from "../Image/token-baloo.png";
import ka from "../Image/token-ka.png";
import kingLouie from "../Image/token-kingLouie.png";
import sherekhan from "../Image/token-sherekhan.png";
import bagheera from "../Image/token-bagheera.png";
import { Carousel } from "react-responsive-carousel";
import CreateButoon from "../ConnectButton";
import { useAccount } from "wagmi";

export default function Home() {
  const { address } = useAccount();

  console.log(address, "account");
  return (
    <div>
      {" "}
      <header id="header" class="fixed-top">
        <div class="container d-flex align-items-center justify-content-end">
          {address ? (
            <a href="/dashbaord" class="get-started-btn scrollto">
              Dashboard
            </a>
          ) : (
            <CreateButoon />
          )}
        </div>
      </header>
      <section class="pt-0 questbg">
        <div class="qstBox">
          <div class="bird-container bird-container-one">
            <div class="bird bird-one"></div>
          </div>
          <div class="bird-container bird-container-three">
            <div class="bird bird-three"></div>
          </div>
          <div class="bird-container bird-container-four">
            <div class="bird bird-four"></div>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <div class="callout">
                  <div class="text-center mstoken">
                    <img
                      src="assets1/img/mowgli-sanctuary-logo.png"
                      alt="Jungle Quest Challenge"
                      class="img-fluid"
                    />
                  </div>
                  <h1>Join the Jungle Quest!</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="why-us">
        <div class="container">
          <div class="row pb-5">
            <div class="col-lg-12">
              <div class="content text-center">
                <h3>How to Participate in</h3>
                <h2 class="text-green font-weight-bold">
                  Jungle Quest Challenge
                </h2>
              </div>

              <div class="row mt-md-4 mt-2">
                <div class="col-lg-4 col-md-2">
                  <div class="boxcomn tp07">
                    {/* <!-- <span class="iconss"><img src="assets/img/community.png" alt=""></span> --> */}
                    <h4>Engage With The Community</h4>
                    <p>
                      Like, comment, and share posts from our community. The
                      more you engage, the more points you earn.
                    </p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-2">
                  <div class="tp08 boxcomn">
                    {/* <!-- <span class="iconss"><img src="assets/img/world.png" alt=""></span> --> */}
                    <h4>Spread The Word</h4>
                    <p>
                      Share our project with your friends and followers. Each
                      new referral earns you bonus points!
                    </p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-2">
                  <div class="tp09 boxcomn">
                    {/* <!-- <span class="iconss"><img src="assets/img/task.png" alt=""></span> --> */}
                    <h4>Complete Daily Tasks</h4>
                    <p>
                      Participate in daily tasks, such as sharing a project
                      update or tagging a friend, to earn extra points.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="skills">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-8 pt-4 pt-lg-0 gtext">
              <div class="boxcomn getmst text-center">
                <h3 class="text-white">
                  The more you share, the more you earn.
                </h3>
                <h2 class="bgcoins">
                  Get 1000 MST{" "}
                  <span class="mscoins">
                    <img src="assets/img/coins.png" alt="coins" />
                  </span>
                </h2>

                <p class="text-white">
                  Share the story with your friends on your favorite social
                  media platform and instantly earn 1000 points!{" "}
                </p>
                <p class="text-white">
                  Spread the word and earn rewards effortlessly.
                </p>
                <h4 class="text-white font-weight-bold">
                  Start sharing now and watch your points grow!
                </h4>
                <div class="button2">
                  <div class="inner">Share Now</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="stry009">
        <div class="container mt-5">
          <div class="content text-center text-white mb-5">
            <h3>Achieve your goals by completion of</h3>
            <h2 class="text-green font-weight-bold">Our Task</h2>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="taskbox  d-md-flex d-block justify-content-between">
                <div>
                  <h2>DAILY</h2>
                  <h3 class="text-white">Task</h3>
                  <p>Earn Extra Points</p>
                </div>
                <div class="align-self-end">
                  <a href="#" class="get-started-btn">
                    View detail
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="taskbox d-md-flex d-block  justify-content-between">
                <div>
                  <h2>WEEKLY</h2>
                  <h3 class="text-white">Task</h3>
                  <p>Earn Extra Points</p>
                </div>
                <div class="align-self-end">
                  <a href="#" class="get-started-btn">
                    View detail
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="taskbox  d-md-flex d-block justify-content-between">
                <div>
                  <h2>MONTHLY</h2>
                  <h3 class="text-white">Task</h3>
                  <p>Earn Extra Points</p>
                </div>
                <div class="align-self-end">
                  <a href="#" class="get-started-btn">
                    View detail
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="shareS09">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-7">
                <div class="boxcomn gr01 text-center">
                  <h3 class="text-white">
                    Invite your friends to join the fun using your referral code
                    and instantly receive{" "}
                  </h3>
                  <h1 class="text-white bgcoins my-3">
                    1200 coins!{" "}
                    <span class="mscoins">
                      <img src="assets1/img/coins.png" alt="coins" />
                    </span>
                  </h1>
                  <h4 class="text-white">
                    Share your code, earn rewards, and let the adventure begin!
                  </h4>
                  <div class="button2 my-3">
                    <a href="#">Share Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="crtstory">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="content text-center text-white mb-5">
                <h3>Introducing the Story Ideas</h3>
                <h2 class="text-green font-weight-bold">Story Templates</h2>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-8">
              <div class="blog_content">
                {/* <div class="owl-carousel owl-theme"> */}
                <Carousel
                  autoPlay
                  showStatus={false}
                  showThumbs={false}
                  showArrows={false}
                >
                  <div class="blog_item">
                    <div class="popBox">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="d-flex flex-column">
                            <div class="position-relative">
                              <img
                                src={mowgli}
                                class="imgglow img-fluid"
                                alt=""
                              />
                              <span class="addnft">
                                <a
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#staticBackdrop"
                                >
                                  <i class="fa fa-plus-circle"></i>
                                </a>
                              </span>
                            </div>

                            <div class="mt-105">
                              <div class="row justify-content-center nft09">
                                <div class="col-4">
                                  <img
                                    src={sherekhan}
                                    class="img-fluid"
                                    alt=""
                                  />
                                </div>
                                <div class="col-4">
                                  <img src={baloo} alt="" class="img-fluid" />
                                </div>
                                <div class="col-4">
                                  <img
                                    src={bagheera}
                                    alt=""
                                    class="img-fluid"
                                  />
                                </div>
                                <div class="col-4">
                                  <img src={ka} alt="" class="img-fluid" />
                                </div>
                                <div class="col-4">
                                  <img
                                    src={kingLouie}
                                    alt=""
                                    class="img-fluid"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-8 offset-md-1">
                          <div class="truncate stbg09">
                            <h4 class="text-white">My Story</h4>
                            <p class="text-white">
                              Once upon a time, in the heart of the lush and
                              vibrant Indian jungle, there lived a young boy
                              named Mowgli. Abandoned as a baby and taken in by
                              a pack of wolves, Mowgli grew up under the care of
                              Mother Wolf and her cubs. From them, he learned
                              the ways of the jungle - how to run with the wind,
                              climb trees with agility, and communicate with the
                              animals through a language of whispers and
                              gestures.
                            </p>
                            {/* <p class="text-white">
                              As Mowgli grew older, he became friends with many
                              of the jungle's inhabitants. There was Bagheera,
                              the sleek and cunning black panther who watched
                              over him like a guardian; Baloo, the wise and
                              jovial bear who taught him the Laws of the Jungle;
                              and Kaa, the ancient python who shared his wisdom
                              in the serpentine coils of his hypnotic embrace.
                            </p>
                            <p class="text-white">
                              But not all creatures in the jungle were friendly.
                              Shere Khan, the fierce and vengeful tiger,
                              harbored a deep hatred for humans and sought to
                              rid the jungle of Mowgli's presence. With his
                              blazing eyes and razor-sharp claws, Shere Khan was
                              a constant threat, lurking in the shadows and
                              plotting his next move.
                            </p> */}
                            <div class="mb-10 d-flex flex-wrap align-items-center">
                              <div>
                                {" "}
                                <div class="button3 text-white mb-2">
                                  <a href="#">Get This Template</a>{" "}
                                </div>
                              </div>
                              <div class="mb-2 text-white mx-2"> Or</div>
                              <div>
                                {" "}
                                <div class="button3 text-white mb-2">
                                  <a href="#">Create Your Own</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="blog_item">
                    <div class="popBox">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="d-flex flex-column">
                            <div class="position-relative">
                              <img
                                src={mowgli}
                                class="imgglow img-fluid"
                                alt=""
                              />
                              <span class="addnft">
                                <a
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#staticBackdrop"
                                >
                                  <i class="fa fa-plus-circle"></i>
                                </a>
                              </span>
                            </div>

                            <div class="mt-105">
                              <div class="row justify-content-center nft09">
                                <div class="col-4">
                                  <img
                                    src={sherekhan}
                                    class="img-fluid"
                                    alt=""
                                  />
                                </div>
                                <div class="col-4">
                                  <img src={baloo} alt="" class="img-fluid" />
                                </div>
                                <div class="col-4">
                                  <img
                                    src={bagheera}
                                    alt=""
                                    class="img-fluid"
                                  />
                                </div>
                                <div class="col-4">
                                  <img src={ka} alt="" class="img-fluid" />
                                </div>
                                <div class="col-4">
                                  <img
                                    src={kingLouie}
                                    alt=""
                                    class="img-fluid"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-8 offset-md-1">
                          <div class="truncate stbg09">
                            <h4 class="text-white">My Story</h4>
                            <p class="text-white">
                              Once upon a time, in the heart of the lush and
                              vibrant Indian jungle, there lived a young boy
                              named Mowgli. Abandoned as a baby and taken in by
                              a pack of wolves, Mowgli grew up under the care of
                              Mother Wolf and her cubs. From them, he learned
                              the ways of the jungle - how to run with the wind,
                              climb trees with agility, and communicate with the
                              animals through a language of whispers and
                              gestures.
                            </p>
                            {/* <p class="text-white">
                              As Mowgli grew older, he became friends with many
                              of the jungle's inhabitants. There was Bagheera,
                              the sleek and cunning black panther who watched
                              over him like a guardian; Baloo, the wise and
                              jovial bear who taught him the Laws of the Jungle;
                              and Kaa, the ancient python who shared his wisdom
                              in the serpentine coils of his hypnotic embrace.
                            </p>
                            <p class="text-white">
                              But not all creatures in the jungle were friendly.
                              Shere Khan, the fierce and vengeful tiger,
                              harbored a deep hatred for humans and sought to
                              rid the jungle of Mowgli's presence. With his
                              blazing eyes and razor-sharp claws, Shere Khan was
                              a constant threat, lurking in the shadows and
                              plotting his next move.
                            </p> */}
                            <div class="mb-10 d-flex flex-wrap align-items-center">
                              <div>
                                {" "}
                                <div class="button3 text-white mb-2">
                                  <a href="#">Get This Template</a>{" "}
                                </div>
                              </div>
                              <div class="mb-2 text-white mx-2"> Or</div>
                              <div>
                                {" "}
                                <div class="button3 text-white mb-2">
                                  <a href="#">Create Your Own</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel>

                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <section class="dist009 video-container2">
          <div class="callout">
            <div class="row align-items-center">
              <div class="col-md-4">
                {" "}
                <div class="text-center mstoken2">
                  <img
                    src="assets1/img/ms-token.png"
                    class="img-fluid vert-move"
                    alt="Jungle Quest Challenge"
                  />
                </div>
              </div>
              <div class="col-md-6 offset-md-2">
                <h1 class="text-green">10,000,000 Token</h1>
                <h3 class="text-white">Total Distribution for Users</h3>
                <h4 class="desc">
                  Embark on a social adventure and earn rewards! Engage with our
                  community, spread the word about our project, and complete
                  daily tasks to earn valuable points.
                </h4>
                <div class="button">
                  <div class="inner">Own Your Token</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section class="cliens">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8">
              {" "}
              <div class="content my-5">
                <h2 class="font-weight-bold text-green">
                  Venture Capitalists & Investors
                </h2>
              </div>
            </div>
          </div>

          <div class="row cli009 aos-init aos-animate">
            <div class="col-lg-2 col-md-4 col-6">
              <img
                src="assets1/img/clients/client-1.png"
                class="img-fluid"
                alt="Jungle Quest Challenge"
              />
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img
                src="assets1/img/clients/client-2.png"
                class="img-fluid"
                alt="Jungle Quest Challenge"
              />
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img
                src="assets1/img/clients/client-3.png"
                class="img-fluid"
                alt="Jungle Quest Challenge"
              />
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img
                src="assets1/img/clients/client-4.png"
                class="img-fluid"
                alt="Jungle Quest Challenge"
              />
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img
                src="assets1/img/clients/client-5.png"
                class="img-fluid"
                alt="Jungle Quest Challenge"
              />
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img
                src="assets1/img/clients/client-6.png"
                class="img-fluid"
                alt="Jungle Quest Challenge"
              />
            </div>
          </div>
          <div class="row mt-md-5 justify-content-center">
            <div class="col-lg-6">
              <div class="gr02 boxcomn">
                {" "}
                <h2 class="text-white">Stay tuned for more info</h2>
                <hr />
                <h4 class="text-white">
                  Keep an eye out for updates and announcements coming your way
                  soon.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer id="footer">
        <div class="text-white footer-newsletter">
          <div class="container">
            <div class="row mt-4">
              <div class="col-lg-7">
                <div class="ftrlogo d-flex justify-content-around flex-wrap">
                  <a href="#">Staking</a>
                  <a href="#">Create Story</a>
                  <a href="#">Events</a>
                  <a href="#">Games</a>
                </div>
              </div>
              <div class="col-lg-5 text-md-right text-center">
                <div class="ftrsocial">
                  <a href="#">
                    <img src="assets1/img/x.png" alt="Instagram" />
                  </a>
                  <a href="#">
                    <img src="assets1/img/insta.png" alt="Instagram" />
                  </a>
                  <a href="#">
                    <img src="assets1/img/telegram.png" alt="Instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container footer-bottom clearfix">
          <div class="copyright text-center">
            <p>&copy; Jungle Quest Challenge</p>
          </div>
        </div>
      </footer>
      <a href="#" class="back-to-top">
        <i class="ri-arrow-up-line"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </div>
  );
}

import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between">
          <span className="d-block text-center text-sm-left d-sm-inline-block">
            &copy; 2024 Mowgli Sanctuary
          </span>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import React from "react";

export default function Task() {
  return (
    <>
      <div className="content-wrapper">
        <div className="row mb-4">
          <div className="col-md-12">
            <h4 className="text-white">My Task</h4>
          </div>
        </div>

        <div className="row gutter">
          <div className="col-md-4">
            <div className="row bg-lime-950 p-3 rounded-md mb-3 bg-opacity-40 border relative m-1">
              <div className="tsk0909">
                <img src="../assets/images/task1.png" alt="Mowgli" />
              </div>
              <div className="col-md-3 nd089">
                <img src="../assets/images/jungle.jpg" alt="Mowgli" />
              </div>
              <div className="col-md-9">
                <h3 className="ovrfl01 text-gray-50 font-bold">
                  This is my first task heading
                </h3>
                <p className="ovrfl02 text-gray-400">
                  he href attribute is required for an anchor to be keyboard
                  accessible. Provide a valid, navigable address as the href
                  value.
                </p>
                <div className="flex justify-between mt-3">
                  <a href="#" className="btn btn-outline-success btn-sm">
                    Completed
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row bg-lime-950 p-3 rounded-md mb-3 bg-opacity-40 border relative m-1">
              <div className="tsk0909">
                <img src="../assets/images/task2.png" alt="Mowgli" />
              </div>
              <div className="col-md-3 nd089">
                <img src="../assets/images/taskIcon.png" alt="Mowgli" />
              </div>
              <div className="col-md-9">
                <h3 className="ovrfl01 text-gray-50 font-bold">
                  This is my first task heading
                </h3>
                <p className="ovrfl02 text-gray-400">
                  he href attribute is required for an anchor to be keyboard
                  accessible. Provide a valid, navigable address as the href
                  value.
                </p>
                <div className="flex justify-between mt-3">
                  <a href="#" className="btn btn-outline-warning btn-sm">
                    Pending
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row bg-lime-950 p-3 rounded-md mb-3 bg-opacity-40 border relative m-1">
              <div className="tsk0909">
                <img src="../assets/images/task2.png" alt="Mowgli" />
              </div>
              <div className="col-md-3 nd089">
                <img src="../assets/images/taskIcon.png" alt="Mowgli" />
              </div>
              <div className="col-md-9">
                <h3 className="ovrfl01 text-gray-50 font-bold">
                  This is my first task heading
                </h3>
                <p className="ovrfl02 text-gray-400">
                  he href attribute is required for an anchor to be keyboard
                  accessible. Provide a valid, navigable address as the href
                  value.
                </p>
                <div className="flex justify-between mt-3">
                  <a href="#" className="btn btn-outline-warning btn-sm">
                    Pending
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import Home1 from "../../Page/Dashboard/Pages/Home";
import Task from "../../Page/Dashboard/Task/inedx";

const routes = [
  {
    id: 1,
    path: "/dashbaord",
    element: <Home1 />,
  },
  {
    id: 1,
    path: "/task",
    element: <Task />,
  },
];
export default routes;

import { ConnectButton } from "@rainbow-me/rainbowkit";
import React from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

export default function CreateButoon() {
  return (
    <div>
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openChainModal,
          openConnectModal,
          authenticationStatus,
          mounted,
        }) => {
          // Note: If your app doesn't use authentication, you
          // can remove all 'authenticationStatus' checks
          const ready = mounted && authenticationStatus !== "loading";
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === "authenticated");

          return (
            <div
              {...(!ready && {
                "aria-hidden": true,
                style: {
                  opacity: 0,
                  pointerEvents: "none",
                  userSelect: "none",
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return (
                    <div>
                      {/* <div className="border lg:p-2 rounded-full">
                        <AccountBalanceWalletIcon
                          onClick={openConnectModal}
                          className="!text-white !font-bold !text-2xl lg:block hidden"
                        />
                      </div> */}

                      <button
                        onClick={openConnectModal}
                        type="button"
                        class="get-started-btn scrollto"
                      >
                        Join Now
                      </button>
                    </div>
                  );
                }

                if (chain.unsupported) {
                  return (
                    <button onClick={openChainModal} type="button">
                      Wrong network
                    </button>
                  );
                }

                return (
                  <div style={{ display: "flex", gap: 20 }}>
                    {/* <button
                      onClick={openChainModal}
                      style={{ display: "flex", alignItems: "center" }}
                      type="button"
                    >
                      {chain.hasIcon && (
                        <div
                          style={{
                            background: chain.iconBackground,
                            width: 40,
                            height: 40,
                            borderRadius: 999,
                            overflow: "hidden",
                            marginRight: 4,
                          }}
                          className="lg:block hidden"
                        >
                          {chain.iconUrl && (
                            <img
                              alt={chain.name ?? "Chain icon"}
                              src={chain.iconUrl}
                              style={{ width: 40, height: 40 }}
                            />
                          )}
                        </div>
                      )}
                      
                    </button> */}

                    <p
                      onClick={openAccountModal}
                      type="button"
                      className="text-xl font-bold text-white "
                    >
                      {account.displayName}
                      {account.displayBalance
                        ? ` (${account.displayBalance})`
                        : ""}
                    </p>
                    {/* <button
                      type="button"
                      className="text-xl font-bold text-white lg:hidden"
                    >
                      <AccountBalanceWalletIcon
                        onClick={openAccountModal}
                        className="!text-white !font-bold !text-2xl lg:block hidden"
                      />
                    </button> */}
                  </div>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
    </div>
  );
}

import React from "react";
import Header from "../../Page/Dashboard/Header/Header";
import TopNav from "../../Page/Dashboard/TopNav";
import Footer from "../../Page/Dashboard/Footer";

const Layout = ({ component }) => {
  return (
    <>
      <div className="container-scroller">
        <Header />
        <div className="container-fluid page-body-wrapper">
          <TopNav />
          <div className="main-panel">
            <div className="h-100 ">{component}</div>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;

import React from "react";

const Header = () => {
  return (
    <>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="#">
            <img src="assets/images/logo.png" />
          </a>
          <a className="sidebar-brand brand-logo-mini" href="#">
            <img src="assets/images/logo-mini.png" />
          </a>
        </div>
        <ul className="nav">
          <li className="nav-item menu-items">
            <a className="nav-link" href="/dashbaord">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">Dashboard</span>
            </a>
          </li>

          <li className="nav-item menu-items">
            <a className="nav-link" href="/task">
              <span className="menu-icon">
                <i className="mdi mdi-table-large"></i>
              </span>
              <span className="menu-title">My Task</span>
            </a>
          </li>

          <li className="nav-item menu-items">
            <a className="nav-link" href="#">
              <span className="menu-icon">
                <i className="mdi mdi-hand-coin"></i>
              </span>
              <span className="menu-title">My Earnings</span>
            </a>
          </li>

          <li className="nav-item menu-items">
            <a
              className="nav-link"
              data-toggle="collapse"
              href="#ui-basic"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <span className="menu-icon">
                <i className="mdi mdi-contacts"></i>
              </span>
              <span className="menu-title">Profile</span>
              {/* <i className="menu-arrow"></i> */}
            </a>
            <div className="collapse" id="ui-basic">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <a className="nav-link" href="myProfile.html">
                    My Profile
                  </a>
                </li>
                <li className="nav-item">
                  {" "}
                  <a className="nav-link" href="changePassword.html">
                    Change Password
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li className="nav-item menu-items">
            <a className="nav-link" href="#">
              <span className="menu-icon">
                <i className="mdi mdi-logout-variant"></i>
              </span>
              <span className="menu-title">Log Out</span>
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Header;

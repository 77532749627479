import React from "react";

const Home1 = () => {
  return (
    <>
      <div className="content-wrapper">
        <div className="row mb-4">
          <div className="col-md-12">
            <h3 className="text-white">Dashboard</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-3">
            <div className="mb-2">
              <div className="text-center rankPoints">
                <div className="point09">
                  <img src="assets/images/points.png" />
                </div>
                <h1 className="text-white">342343.00</h1>
                <h4 className="text-white font-weight-light">
                  Total Points you earned
                </h4>
                <a href="#" className="btn btn-inverse-dark">
                  Claim Now
                </a>
              </div>
            </div>
            <div className="mb-2">
              <div className="taskAct d-flex align-items-center">
                <div className="tskimg mr-3">
                  <img src="assets/images/taskIcon.png" />
                </div>
                <div className="flex-grow-1">
                  <h3 className="text-white mb-1 mt-1"> 12.00</h3>
                  <p className="text-white-50">Completed Task</p>
                </div>
              </div>
            </div>
            <div className="mb-2">
              <div className="taskAct d-flex align-items-center">
                <div className="tskimg mr-3">
                  <img src="assets/images/taskIcon.png" />
                </div>
                <div className="flex-grow-1">
                  <h3 className="text-warning mb-1 mt-1"> 12.00</h3>
                  <p className="text-white-50">Pending Task</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-9">
            <div className="taskBox">
              <h3 className="text-white mb-3">Daily Task</h3>
              <div className="dlt09">
                <div className="row">
                  <div className="col-md-8">
                    <h5 className="lineClamp">
                      Enhance Twitter Profile Optimization to Attract More
                      Followers and Engagement Enhance Twitter Profile
                      Optimization to Attract More Followers and
                      EngagementEnhance Twitter Profile Optimization to Attract
                      More Followers and Engagement
                    </h5>
                    <div className="small text-muted mb-3">
                      <i className="mdi mdi-clock"></i> - 1 June, 2024 |{" "}
                      <i className="mdi mdi-account"></i> - Admin{" "}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="tsktime d-flex justify-content-around align-items-center">
                      <div className="sci09 text-center">
                        <img src="assets/images/social3.png" alt="Mowgli" />
                      </div>
                      <div className="text-white">
                        <h4 className="text-white">
                          Time left = <span id="timer">00:30:00</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="dlt09">
                <div className="row">
                  <div className="col-md-8">
                    <h5>
                      Enhance Twitter Profile Optimization to Attract More
                      Followers and Engagement
                    </h5>
                    <div className="small text-muted mb-3">
                      <i className="mdi mdi-clock"></i> - 1 June, 2024 |{" "}
                      <i className="mdi mdi-account"></i> - Admin{" "}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="tsktime d-flex justify-content-around align-items-center">
                      <div className="sci09 text-center">
                        <img src="assets/images/social2.png" alt="Mowgli" />
                      </div>
                      <div className="text-white">
                        <h4 className="text-white">
                          Time left = <span id="timer">00:30:45</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home1;
